<template>
  <AuctionHeader :activeSearchDesktop="false" :activeSearchMobile="false" />
  <section class="bg-agrogo-green-2">
    <div class="grid grid-cols-3 bg-agrogo-yellow-2 h-20 md:h-24">
      <div class="flex h-auto w-auto items-center justify-end">
        <span
          class="font-nexa font-black text-6xl md:text-7xl text-agrogo-yellow-2"
          >05</span
        >
      </div>
      <div
        class="col-span-2 flex flex-col items-start justify-center ml-5 md:ml-8"
      >
        <span class="font-nexa font-black text-base md:text-2xl mb-3 text-left"
          >Datos de <span class="font-normal"> Depósito Bancario</span>
        </span>
        <div class="flex items-center space-x-4 md:space-x-7 md:text-base">
          <span class="icon-punto text-agrogo-green-3"></span>
          <span class="icon-punto text-white"></span>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 mx-14 mt-8 mb-6 gap-y-4 md:gap-y-3 md:mt-6 md:mb-12 container-publishauction5-desktop md:mx-24 lg:mx-48 xl:mx-0"
    >
      <div
        class="grid grid-cols-2 gap-x-5 bg-container-steps-auction md:min-h-64 md:pl-4 md:pr-5"
      >
        <div class="flex items-center justify-start">
          <p
            class="font-nexa font-normal text-xs text-white text-left md:text-lg"
          >
            Nombres y Apellidos/ Razón Social
          </p>
        </div>

        <div class="flex items-center justify-end">
          <input type="text" class="input-standard-transparent md:max-w-236" />
        </div>
      </div>
      <div
        class="grid grid-cols-2 gap-x-5 bg-container-steps-auction md:min-h-64 md:pl-4 md:pr-5"
      >
        <div class="flex items-center justify-start">
          <p
            class="font-nexa font-normal text-xs text-white text-left md:text-lg"
          >
            Cédula de ciudadanía / NIT
          </p>
        </div>

        <div class="flex items-center justify-end">
          <input type="text" class="input-standard-transparent md:max-w-236" />
        </div>
      </div>
      <div
        class="grid grid-cols-2 gap-x-5 bg-container-steps-auction md:min-h-64 md:pl-4 md:pr-5"
      >
        <div class="flex items-center justify-start">
          <p
            class="font-nexa font-normal text-xs text-white text-left md:text-lg"
          >
            Entidad Bancaria
          </p>
        </div>

        <div class="flex items-center justify-end">
          <v-select class="style-chooser2 w-full md:max-w-236">
            <template #no-options>Sin opciones</template>
          </v-select>
        </div>
      </div>
      <div
        class="grid grid-cols-2 gap-x-5 bg-container-steps-auction md:min-h-64 md:pl-4 md:pr-5"
      >
        <div class="flex items-center justify-start">
          <p
            class="font-nexa font-normal text-xs text-white text-left md:text-lg"
          >
            Tipo de Cuenta
          </p>
        </div>

        <div class="flex items-center justify-end">
          <v-select class="style-chooser2 w-full md:max-w-236">
            <template #no-options>Sin opciones</template>
          </v-select>
        </div>
      </div>
      <div
        class="grid grid-cols-2 gap-x-5 bg-container-steps-auction md:min-h-64 md:pl-4 md:pr-5"
      >
        <div class="flex items-center justify-start">
          <p
            class="font-nexa font-normal text-xs text-white text-left md:text-lg"
          >
            Número de Cuenta
          </p>
        </div>

        <div class="flex items-center justify-end">
          <input type="text" class="input-standard-transparent md:max-w-236" />
        </div>
      </div>
    </div>
    <div
      class="bg-agrogo-yellow-1 h-14 md:h-20 flex items-center justify-center space-x-3 md:space-x-4 lg:space-x-4"
    >
      <button @click="previus()" type="button" class="btn-primary">
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-agrogo-yellow-2 text-xs mr-1 leading-none"
        ></span>
        Anterior
      </button>
      <button @click="next()" type="button" class="btn-primary">
        Siguiente
        <span
          class="icon-desplegar flex items-center transform -rotate-90 text-agrogo-yellow-2 text-xs ml-1 leading-none"
        ></span>
      </button>
    </div>
  </section>
</template>
<script>
import AuctionHeader from "@/components/header/HeaderAuction";
export default {
  components: {
    AuctionHeader,
  },
};
</script>
